import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BreakLine from '../components/atoms/breakLine'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1.5rem'
  },
  faqBox: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  heading: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
  }
}));

const query = graphql`
  query {
    allContentfulFaq(sort: {fields: [order], order: ASC}) {
      edges {
        node {
          answer {
            answer
          }
          question {
            question
          }
        }
      }
    }
  }
`

const Faq = () => {
  const classes = useStyles();
  const contentful = useStaticQuery(query)

  return <>
    <Typography className={classes.title} variant="h6" gutterBottom>
      よくあるお問い合わせ
    </Typography>
    <Typography className={classes.faqBox}>
      {contentful.allContentfulFaq.edges.map(({ node }) =>
        <Accordion key={node.question.question} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.heading}
          >
            <Typography>{node.question.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <BreakLine value={node.answer.answer} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Typography>
  </>;
}

export default Faq;